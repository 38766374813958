.login-left-part {
  background: rgba(27, 124, 229, 1);
  height: 95vh;
  padding: 25px;
  position: relative;
  /* margin: 15px 0; */
}

.individual-signin {
  padding: 0 15px;
}

.login-banner img {
  width: 300px;
  height: 300px;
}

/* .individual-form fieldset {
    width: 450px;
    background: rgba(255, 255, 255, 1);
    position: absolute;
    z-index: -1;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
    border: none;
} */

.individual-form .MuiInputBase-root {
  height: 60px;
}

.individual-form {
  margin-top: 70px;
}

.individual-form .MuiFormControl-root {
  margin-top: 10px;
  width: 100%;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
}

.individual-submit a {
  background: #1b7ce5;
  color: #fff;
  border-radius: 4px;
  width: fit-content;
  padding: 16px 45px;
}

/* .individual-submit {
    margin-top: 15px;
} */

.forget-link {
  margin: 5px 0;
  /* max-width: 62%; */
  text-align: end;
}

.individual-submit.save-btn {
  margin-top: 100px;
}

.forget-link a {
  color: #000;
  font-size: 14px;
}

.register-acctn {
  margin-top: 95px;
}

.register-acctn p {
  color: #9a9a9a;
  font-family: Poppins;
  padding: 15px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.register-acctn p a {
  color: #000;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.login-grid {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  padding: 0 50px;
  max-width: 600px;
  /* margin-top: 100px; */
  height: 95%;
}

.register-acctn.trouble-shoot {
  text-align: end;
  margin-top: 0;
}

.login-grid h1 {
  margin: 0;
}

.register-acctn.sign-acctn {
  margin-top: 50px;
}

.phr .MuiFormControl-root input::placeholder,
.crpswd .MuiFormControl-root input::placeholder {
  color: #8d8484;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fllname {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fllname span {
  color: #c6c6c6;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cracct {
  color: #0d1d54;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cracct-desc {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ver-hd {
  color: #fff;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px;
  text-align: center;
  margin-top: 50px;
}

.ver-p {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 354px;
  margin: 10px auto;
  text-align: center;
}

.vrfbtn button {
  border-radius: 4px;
  background: #1b7ce5;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 250px;
  height: 50px;
  text-transform: capitalize;
}

.vrfbtn {
  margin-top: 50px;
  text-align: center;
}

.crpswd-txt {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.crpswd .MuiFormControl-root {
  width: 100%;
  /* margin: auto; */
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
}

.cr-whole {
  width: 75%;
  margin: 15px auto;
}

/* .email-filed {
    width: 380px;
} */

/* .login-banner{} */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  border: 1px solid #fff;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #dbdbdbb5 !important;
}
