.indivi-appbar {
    background-color: rgba(255, 255, 255, 0.80) !important;
}

.indi-h-lgo img {
    /* width: 110px; */
    /* height: 20px; */

}

.ovrvi {
    color: #000;

    /* Semibold/Type@14 */
    /* font-family: "Plus Jakarta Sans"; */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    /* 21px */
    letter-spacing: -0.28px;
}

a.activeH2 {
    border-radius: 10px;
    background: var(--main, #1B7CE5);
    padding: 10px 15px;
}


a.activeH2 .ovrvi {
    color: #fff;
}

a.activeH2 svg path {
    stroke: #fff !important;
}

.outer {
    border-radius: 100px;
    border: 1px solid var(--main, #1B7CE5);
    padding: 12px;

}